import React from 'react'
import './Footer.css'

function Footer() {
    return (
<footer className="page-footer footer">
<div className="footer-copyright text-center py-3  text-white"><b>Copyright © Trishati 2019-{new Date().getFullYear()} . All rights reserved</b>
</div>
</footer>
    )
}

export default Footer
