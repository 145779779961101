import React from "react";
import Navigation from "../Navigation/Navigation";
import ServiceCard from "../ServiceCard/ServiceCard";
import Footer from "../Footer/Footer";
// import Marquee from '../Marquee/Marquee'
import BannerSection from "../LandingPage.js/BannerSection";
import WhyUs from "../WhyUs/WhyUs";

function Main() {
  return (
    <div>
      {/* <Marquee /> */}
      <Navigation />
      <BannerSection />
      <WhyUs />
      <ServiceCard />
      <Footer />
    </div>
  );
}

export default Main;
