import React from "react";
import "./ServiceCard.css";

function ServiceCard() {
  return (
    <div className="services container  team-boxed">
      <h3 className="text-center  text-capitalize my-5 ">Our Services</h3>
      <div className="row">
        <div className="col-md-4 cards1">
          <div className="card shadow">
            <div className="inner bg-image hover-overlay hover-zoom hover-shadow ripple">
              <img
                src="/images/webDevelopeImage.jpg"
                height="250px"
                className="card-img-top"
                alt="..."
              />
            </div>
            <div className="card-body text-center">
              <h6 className="card-title text-dark">
                <b>Web Designing</b>
              </h6>
            </div>
          </div>
        </div>
        <div className="col-md-4 cards2">
          <div className="card shadow">
            <div className="inner">
              <img
                src="/images/webdesigning.jpg"
                height="250px"
                className="card-img-top"
                alt="..."
              />
            </div>
            <div className="card-body text-center">
              <h6 className="card-title text-dark">
                <b>Product Develpoment</b>
              </h6>
            </div>
          </div>
        </div>
        <div className="col-md-4 cards1">
          <div className="card shadow">
            <div className="inner">
              <img
                src="/images/webHostingImage.jpg"
                height="250px"
                className="card-img-top"
                alt="..."
              />
            </div>
            <div className="card-body text-center">
              <h6 className="card-title text-dark">
                <b>Web Hosting</b>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
