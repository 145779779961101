import React from 'react'
import './WhyUs.css'

function WhyUs() {
    return (
        <div className="whyUs">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 160"><path fill="#fff" fillOpacity="1" d="M0,160L80,138.7C160,117,320,75,480,74.7C640,75,800,117,960,117.3C1120,117,1280,75,1360,53.3L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>

            <div className="row text-center">
                <h2 className=" mb-3"><b>Why Us</b></h2>
                <div className="col-md-4">
                    <img src="/images/whyUs4.jpg" alt="webDevImage" width="100%" />
                </div>
                <div className="text my-5 col-md-8  text-sm-left">
                <h4 >
                <hr className="text-white"  />
                    We began in the year 2019, headquartered in Bangalore, with the aim to provide dedicated quality services in Web design and Software product development. </h4>
                  <h4 className="mt-4">With our proficient team, we offer services in a wide-range starting from Product development, Website designing and Website hosting. Choose us to experience a professional, creative and promising service to all your Software needs.
                    Your quest for the experiencing a remarkable service ends here!!!</h4>
                    <hr className="text-white" />
                    </div>
            </div>
        </div>
    )
}

export default WhyUs
