import React from "react";
import Footer from "../Footer/Footer";
import Marquee from "../Marquee/Marquee";
import Navigation from "../Navigation/Navigation";
import "./ContactUs.css";

function ContactUs() {
  return (
    <div>
      <Marquee />
      <Navigation />

      <div className=" contactUs">
        <div className="card-body text-center">
          <h2>Contact Us</h2>
          <hr />
          <h4 className="text-secondary mt-4">
            If you want best service call us now
          </h4>
          <h6 className="mt-4 text-dark">
            <i className="fas fa-phone text-success"> +91 9036139555</i>
          </h6>
          <h6 className="text-dark">
            <i className="far fa-envelope text-primary">
              {" "}
              Mail : contact@trishati.com
            </i>
          </h6>
        </div>
        <img
          src="/images/contactImg.jpg"
          className="card-img-bottom"
          alt="..."
        />
      </div>

      <Footer />
    </div>
  );
}

export default ContactUs;
