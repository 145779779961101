import React from "react";
import Footer from "../Footer/Footer";
import Marquee from "../Marquee/Marquee";
import Navigation from "../Navigation/Navigation";
import "./Services.css";

function Services() {
  return (
    <div>
      <Marquee />
      <Navigation />
      <div className="services">
        <h3 className="text-center my-4">Services offered by us</h3>
        <div className="text">
          <p>
            Trishati uniquely aims in providing you the stunning and most
            appealing website design!!! Our teams works carefully to craft every
            part of your the website to provide a matchless user-experience.We
            provide customized services from small to big companies as per their
            business needs. We not only make new websites, we also assist in
            innovating your older websites.We offer our services at a throw away
            price, with a promise of customer satisfaction!!!
          </p>
        </div>
      </div>

      <div></div>

      <div className="team-boxed container">
        <div className="row mt-5">
          <div className="col-md-4 cards1 mb-5">
            <div className="card shadow">
              <div className="inner bg-image hover-overlay hover-zoom hover-shadow ripple">
                <img
                  src="/images/webDevelopeImage.jpg"
                  height="250px"
                  className="card-img-top"
                  alt="..."
                />
              </div>
              <div className="card-body text-center">
                <h6 className="card-title text-dark">
                  <b>Web Designing</b>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 cards2">
            <div className="card shadow">
              <div className="inner">
                <img
                  src="/images/webdesigning.jpg"
                  height="250px"
                  className="card-img-top"
                  alt="..."
                />
              </div>
              <div className="card-body text-center">
                <h6 className="card-title text-dark">
                  <b>Product Develpoment</b>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 cards1">
            <div className="card shadow">
              <div className="inner">
                <img
                  src="/images/webHostingImage.jpg"
                  height="250px"
                  className="card-img-top"
                  alt="..."
                />
              </div>
              <div className="card-body text-center">
                <h6 className="card-title text-dark">
                  <b>Web Hosting</b>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Services;
