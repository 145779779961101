import React from "react";
import "./Navigation.css";
// import companyLogo from "./images/trishati-low-resolution-color-logo_192_154.png";

function Navigation() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg  headerNav m-0 p-0">
        <img
          // className="float-left"
          height="100px"
          src={
            process.env.PUBLIC_URL +
            "./images/trishati-low-resolution-color-logo_192_154.png"
          }
          alt="Trishati"
        />
        <div className="container">
          {/* <a className="navbar-brand">
            {" "}
            <img src="/images/trishati-low-resolution-color-logo_192_154.png"></img>
          </a> */}

          <button
            className="navbar-toggler navbar-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/aboutUs">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/services">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contactUs">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
