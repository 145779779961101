import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import AboutUs from './Components/AboutUs/AboutUs'
import { Route, BrowserRouter as Router } from "react-router-dom";
import Services from './Components/Services/Services';
import ContactUs from './Components/ContactUs/ContactUs';

const routing = (
  <Router>
    <div>
      <Route exact path="/" component={App} /> 
      <Route exact path="/aboutUs" component={AboutUs} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/contactUs" component={ContactUs} />
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));
