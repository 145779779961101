import React from 'react'
import Footer from '../Footer/Footer'
import Marquee from '../Marquee/Marquee'
import Navigation from '../Navigation/Navigation'
import './AboutUs.css'

function AboutUs() {
    return (
        <div className="About">
            <Marquee />
            <Navigation />
            <div className="mb-5" >
  <div className="row no-gutters">
    <div className="col-md-4">
      <img src="/images/About.jpg" className="card-img" alt="..." />
    </div>
    <div className="col-md-8">
      <div className="card-body">
      <h2 className="card-title mb-4">About Us</h2>
        <p className="card-text text-secondary">Our Mission Aims to provide matchless Website Designs!!! Trishati started on June 2019, headquartered in Bangalore. Our acute and innovative teams looks forward to create milestones in making pleasing designs, with clear headers, concise descriptions , links embellished with little graphics to display what the business emphasizes to every user who visits their sites, aiming to provide an unambiguous experience!!</p>
        <p className="card-text text-secondary">Collaboration Looking for an exciting collaboration in web development? why should you collaborate with us? We ensure to build your knowledge in web development to unleash potential in building your experience. We are looking for active and energetic minds who are passionate in web development. Even if you don't hold the skill set we are ready to train you to explore your best!! If you are having the skill set, and looking for a platform to turn it into experience, we would love to!! And if you are experienced and bored of your old job, we are here to make your work life amazing. We don't care much about your academics. But we do care about how passionate are you to work with us, your creativity, your commitment and your attitude to make our work environment wonderful!!!!
</p>

      </div>
    </div>
  </div>
</div>
            <Footer />
        </div>
    )
}

export default AboutUs
