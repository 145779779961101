import React from 'react'
import './Marquee.css'

function Marquee() {
    return (
        <div>
           {/* <marquee behavior="scroll" direction="left"><h5>Welcome To Om Trishati India Private Limited</h5></marquee> */}
        </div>
    )
}

export default Marquee
