import React from 'react'
import './BannerSection.css'
function BannerSection() {
  return (
    <div>
      <section id="banner" >
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img src="/images/webDesign.jpg" alt="" className="bannerImg img-fluid" />
              </div>
              <div className="col-md-6 title">
                <h1 className="animate__animated animate__slideInLeft">Start Your Website Here</h1>
                <h6 className="text-secondary animate__animated animate__slideInRight">Finest Brand Heading Towards Excellence!!!</h6>
                <a type="button" className="btn  btn-lg shadow p-3 mb-5  mt-3  rounded-pill" href="/contactUs" ><b>Get Started </b></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default BannerSection
